<template>
  <div class="inner-section">
    <card>
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">
          {{ $t('elearning_tim.attendance') }}
          <slot v-if="$route.query.id">
            {{ $t('globalTrans.update') }}
          </slot>
          <slot v-else>
            {{ $t('globalTrans.entry') }}
          </slot>
        </h4>
      </template>
      <template v-slot:searchHeaderAction>
        <router-link to="attendance" class="btn-add"><i class="ri-arrow-left-line"></i> {{ $t('elearning_tim.attendance') }} {{ $t('globalTrans.list') }}</router-link>
      </template>
      <template v-slot:searchBody>
        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
          <b-form class="p-2" id="form" @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
            <b-row>
              <b-col lg="6" sm="6">
                <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="fiscal_year_id"
                    slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                    </template>
                    <v-select
                        v-model="formData.fiscal_year_id"
                        label="text"
                        :reduce="item => item.value"
                        :options= fiscalYearList
                        :placeholder="$t('globalTrans.select')"
                        :state="errors[0] ? false : (valid ? true : null)"
                        :filter-by="myFilter"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                      </template>
                    </v-select>
                    <div class="invalid-feedback d-block">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="6" sm="6">
                <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="circular_memo_no"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                        plain
                        v-model="formData.circular_memo_no"
                        :options="circularList"
                        id="circular_memo_no"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-overlay :show="load">
              <b-row>
                <b-col lg="6" sm="6" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">
                  <ValidationProvider name="Organization"  vid="org_id" rules="required|min_value:1">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="org_id"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.organization')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                          plain
                          v-model="formData.org_id"
                          :options="orgList"
                          id="org_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          disabled
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="training_type_id"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="formData.training_type_id"
                        :options="trainingTypeList"
                        id="training_type_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        disabled
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Training Title" vid="training_title_id" rules="required|min_value:1">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="training_title_id"
                      slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="formData.training_title_id"
                        :options="trainingTitleList"
                        id="training_title_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        disabled
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Training Start Date" vid="training_start_date" rules="required">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="training_start_date"
                        slot-scope="{ valid, errors }"
                    >
                        <template v-slot:label>
                        {{$t('elearning_iabm.training_start_date')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                            class="fromDate"
                            v-model="formData.training_start_date"
                            :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                        </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Training End Date" vid="training_end_date" rules="required">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="training_end_date"
                        slot-scope="{ valid, errors }"
                    >
                        <template v-slot:label>
                        {{$t('elearning_iabm.training_end_date')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                            class="fromDate"
                            v-model="formData.training_end_date"
                            :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                        </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Batch" vid="batch_id" rules="required|min_value:1">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="batch_id"
                      slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.batch')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="formData.batch_id"
                        :options="batchList"
                        id="batch_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <br/>
              </b-row>
              <body-card>
                <template v-slot:headerTitle>
                  <h4 class="card-title">{{$t('elearning_tim.attendance')}} {{$t('globalTrans.list')}}</h4>
                </template>
                <template v-slot:body>
                  <row>
                    <div class="col-md-12">
                      <b-overlay :show="loading">
                        <b-table-simple hover small responsive bordered>
                          <b-tr style="background: #f3f3f3">
                            <b-th rowspan="2" class="text-center" style="vertical-align:middle">{{$t('globalTrans.sl_no')}}</b-th>
                            <b-th rowspan="2" style="vertical-align:middle">{{$t('elearning_tim.trainee')}}</b-th>
                            <b-th rowspan="2" class="text-center" style="vertical-align:middle">{{$t('globalTrans.designation')}}</b-th>
                            <b-th rowspan="2" style="vertical-align:middle">{{$t('globalTrans.mobile')}}</b-th>
                            <b-th style="width:10%;vertical-align:middle" class="text-center" :colspan="dateList.length * 2">{{ $t('globalTrans.date') }}</b-th>
                          </b-tr>
                          <b-tr>
                            <slot v-for="(field, index) in dateList">
                              <b-th class="text-center" :id="'morning-' + field.value">
                                <div>
                                <b-form-checkbox
                                  unchecked-value="0"
                                  :id="'morning-' + index"
                                  v-model="field.morning"
                                  value=2
                                  @change="checkData(field, 'morning')"
                                  >
                                  {{ field.text | dateFormat }}
                                </b-form-checkbox>
                              </div>
                              </b-th>
                            </slot>
                          </b-tr>
                          <b-tr v-for="(train,index) in details" :key="index">
                            <b-td class="text-center">{{$n(index+1)}}</b-td>
                            <b-td>
                              {{ ($i18n.locale==='bn') ? train.name_bn : train.name }}
                            </b-td>
                            <b-td class="text-center">
                              {{ ($i18n.locale==='bn') ? train.designation_name_bn : train.designation_name }}
                            </b-td>
                            <b-td>
                              {{ train.mobile }}
                              <small v-if="detailsErrors && detailsErrors['details.' + index + '.mobile']" class="text-danger">
                                {{ detailsErrors['details.'+index+'.mobile'] }}
                              </small>
                            </b-td>
                            <slot v-for="(field, index1) in dateList">
                              <b-th class="text-center">
                                <input type="checkbox" v-model="details[index].dateList[index1].morning">
                              </b-th>
                            </slot>
                          </b-tr>
                          <template v-if="details.length === 0">
                            <tr>
                              <th :colspan="dateList.length + 5" class="text-center text-danger">{{$t('globalTrans.noDataFound')}}</th>
                            </tr>
                          </template>
                        </b-table-simple>
                      </b-overlay>
                    </div>
                  </row>
                </template>
              </body-card>
              <div class="row">
                <div class="col-sm-3"></div>
                <div class="col text-right">
                  <b-button type="submit" variant="success" class="mr-1 btn-sm">{{ saveBtnName }}</b-button>
                    &nbsp;
                    <router-link to="attendance" class="mr-1 btn btn-danger btn-sm">{{ $t('globalTrans.cancel') }}</router-link>
                </div>
              </div>
            </b-overlay>
          </b-form>
        </ValidationObserver>
      </template>
    </card>
  </div>
</template>
<script>

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { attendanceStore, circularPublication, batchWiseApplicationList, circularPublicationList } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    this.getCircularList()
    this.formData = Object.assign({}, this.formData, {
      fiscal_year_id: this.$store.state.currentFiscalYearId
    })
  },
  mounted () {
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      loading: false,
      load: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        circular_memo_no: 0,
        fiscal_year_id: 0,
        org_id: 0,
        batch_id: 0,
        training_type_id: 0,
        training_title_id: 0,
        training_date: '',
        trainer_id: 0,
        sort_status: 2,
        training_start_date: '',
        training_end_date: '',
        details: []
      },
      details: [],
      batchList: [],
      detailsErrors: [],
      trainerLoading: false,
      circularList: [],
      circularLoading: false,
      attachments_errors: [],
      trainingTitleList: [],
      circularWiseAttendanceList: [],
      detailErrors: [],
      dateList: [],
      myFilter: (option, text, search) => {
        const temp = search.toLowerCase()
        return option.text_en.toLowerCase().indexOf(temp) > -1 ||
        option.text_bn.toLowerCase().indexOf(temp) > -1
      }
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    },
    fiscalYearList: function () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    trainingTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    }
  },
  watch: {
    'formData.circular_memo_no': function (newValue) {
      this.getCircularMemoNo(newValue)
    },
    'formData.fiscal_year_id': function (newValue) {
      this.getCircularList()
    },
    'formData.training_type_id': function (newValue) {
      this.trainingTitleList = this.getTrainingTitleList(newValue)
    },
    'formData.org_id': function (newValue) {
      this.batchList = this.getBatchList(newValue)
    },
    'formData.batch_id': function (newValue) {
      this.circularWiseAttendance()
    }
  },
  methods: {
    getBatchList (orgId) {
      const batchList = this.$store.state.TrainingElearning.commonObj.batchList.filter(item => item.status === 1)
      if (orgId) {
        return batchList.filter(item => item.org_id === orgId)
      }
      return batchList
    },
    checkCount (item) {
      const total = this.dateList.filter(item1 => item1.checked === '2')
      if (total.length > 10) {
        this.$toast.error({
          title: 'Error',
          message: this.$i18n.locale === 'bn' ? 'ইতিমধ্যে সব তারিখ যোগ করা হয়েছে!' : 'All Dates Already Added!'
        })
        item.checked = false
        item.checAll = false
      }
    },
    async getCircularList () {
      this.circularLoading = true
      const serchData = {
        fiscal_year_id: this.formData.fiscal_year_id
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublicationList + '/' + 'iab_circular_publications', serchData)
      if (!result.success) {
      } else {
         const listObject = result.data
        const tmpList = listObject.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no }
          } else {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no }
          }
        })
        this.circularList = tmpList
      }
      this.circularLoading = false
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.training_type_id === categoryId)
      }
      return trainingTitleList
    },
    checkData (item, type) {
      this.details.forEach((detail, detailIndex) => {
        detail.dateList.forEach((dateList, dateListIndex) => {
          if (dateList.text === item.text) {
            if (type === 'morning') {
              if (item.morning === '2') {
                dateList.morning = true
              } else {
               dateList.morning = false
              }
            }
          }
        })
      })
    },
    async circularWiseAttendance () {
      this.loading = true
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, batchWiseApplicationList, this.formData)
      if (result.success) {
        const listData = result.aryRange.map(item => {
          const updateData = {}
          updateData.checked = false
          updateData.morning = false
          updateData.text = item
          return Object.assign({}, updateData)
        })
        this.dateList = listData
        this.circularWiseAttendanceList = result.data
        const datas = result.trainingData.map(item => {
            var dataEvent = []
            const desigData = {}
            desigData.designation_name = item.designation_en
            desigData.designation_name_bn = item.designation_bn
            this.dateList.forEach((dateList) => {
              const saveObj = this.circularWiseAttendanceList.find(circularWiseAttendance => circularWiseAttendance.training_application_id === item.id && circularWiseAttendance.training_date === dateList.text)
              const items = {}
              items.training_application_id = item.id
              if (typeof saveObj !== 'undefined') {
                items.morning = saveObj.morning
                items.text = dateList.text
              } else {
                items.morning = false
                items.text = dateList.text
              }
              dataEvent.push(items)
            })
            const newData = {
              dateList: dataEvent
            }
            return Object.assign({}, item, desigData, newData)
          })
        this.details = datas
        this.loading = false
      } else {
        this.circularWiseAttendanceList = []
        this.details = []
        this.dateList = []
        this.loading = false
      }
    },
    async getCircularMemoNo (getCircularMemoNo) {
      this.load = true
      const params = {
        circular_memo_no: getCircularMemoNo,
        table: 'iab_circular_publications'
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublication, params)
      if (!result.success) {
        this.formData.org_id = 0
        this.formData.training_title_id = 0
        this.formData.training_type_id = 0
        this.formData.training_start_date = ''
        this.formData.training_end_date = ''
      } else {
        this.lcErrorMsg = ''
        const trData = result.data
        this.formData.org_id = trData.org_id
        this.formData.training_title_id = trData.training_title_id
        this.formData.training_type_id = trData.training_type_id
        this.formData.training_start_date = result.data.training_start_date
        this.formData.training_end_date = result.data.training_end_date
        this.load = false
      }
      this.dateList = []
      this.load = false
    },
    async createData () {
      this.loading = true
      let result = null
      this.formData.details = this.details
      result = await RestApi.postData(trainingElearningServiceBaseUrl, attendanceStore, this.formData)
      this.loading = false
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$router.push('/training-e-learning-service/tim/attendance')
      } else {
        this.detailErrors = result.errors
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
